import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    isSignedIn: false
  },
  mutations: {
    signIn (state, user) {
      state.user = user
      state.isSignedIn = true
      localStorage.setItem('user', JSON.stringify(user))
    },
    signOut (state) {
      state.user = null
      state.isSignedIn = false
    }
  },
  actions: {
    signIn (context, user) {
      context.commit('signIn', user)
    },
    signOut (context) {
      context.commit('signOut')
    }
  },
  modules: {
  }
})
