<template>
  <div class="flex-container">
    <div class="row">
      <div class="flex-item"><img @click="signInWithGoogle()" src="@/assets/btn_google_signin_dark_normal_web.png"/></div>
      <div class="flex-item"><img @click="signInWithMicrosoft()" src="@/assets/ms-symbollockup_signin_light.png"/></div>
    </div>
  </div>
</template>

<script>
import { OAuthProvider, signInWithPopup } from 'firebase/auth'

export default {
  name: 'SignIn',
  methods: {
    signInWithGoogle () {
      this.signInWithPopup(new OAuthProvider('google.com'))
    },
    signInWithMicrosoft () {
      this.signInWithPopup(new OAuthProvider('microsoft.com'))
    },
    signInWithPopup (provider) {
      signInWithPopup(this.$auth, provider).then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result)
        // const token = credential.accessToken
        // const user = result.user
        console.log(result)
      }).catch((error) => {
        alert('nope!')
        console.error(error)
      })
    }
  }
}
</script>
<style>
.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: auto;
}
.flex-item {
  margin: 10px;
  text-align: center;
  cursor: pointer;
}</style>
