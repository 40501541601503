import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SignIn from '../views/SignIn.vue'
import EditWorkflow from '../views/EditWorkflow.vue'
import ListProcesses from '../views/ListProcesses.vue'
// import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/list-processes',
    name: 'ListProcesses',
    component: ListProcesses
  },
  // {
  //   path: '/admin',
  //   name: 'Admin',
  //   component: Admin,
  //   beforeEnter: (to, from, next) => {
  //     // reject the navigation
  //     console.log('isadmin', store.state.user.isAdmin)
  //     next(store.state.user.isAdmin === true)
  //   }
  // },
  {
    path: '/organizations/:organizationId/workflows/:workflowId/',
    name: 'edit-workflow',
    component: EditWorkflow
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
