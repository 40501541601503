<template>
    <div style="box-shadow: 0 0 4px 2px #ccc; z-index: 1; position: fixed; background-color: white; left: 0; right: 0; top: 0; height: 50px; border-bottom: 1px solid #aaa; line-height: 50px; padding: 0 20px; display: flex; justify-content: space-between; align-items: center;">
      <div id="nav">
        {{ title }}
      </div>
      <div>
        {{ name }}&nbsp;&middot;&nbsp;<button @click="signOut()">Sign Out</button>
      </div>
    </div>
</template>

<script>
import { signOut } from 'firebase/auth'

export default {
  name: 'ViewHeader',
  props: {
    title: String
  },
  methods: {
    signOut () {
      signOut(this.$auth)
    }
  }
}
</script>
