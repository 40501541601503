<template>
  <div class="flex-container">
    <div class="row">
      <div v-for="organization in organizations" :key="organization.index" class="flex-item">
        {{ organization.name }}
        <hr />
        <div v-for="workflow in organization.workflows" :key="workflow.index">
          <router-link :to="{ name: 'edit-workflow', params: { organizationId: organization.id, workflowId: workflow.id }}">{{ workflow.name }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from 'firebase/firestore'

export default {
  name: 'ListProcesses',
  data: function () {
    return {
      organizations: []
    }
  },
  async mounted () {
    let querySnapshot = await getDocs(collection(this.$firestore, 'organizations'))
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      this.organizations.push({
        id: doc.ref.id,
        name: doc.data().name
      })
    })

    for (const organization of this.organizations) {
      const workflows = []
      querySnapshot = await getDocs(collection(this.$firestore, `/organizations/${organization.id}/workflows`))
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        workflows.push({
          id: doc.ref.id,
          name: doc.data().name
        })
      })
      this.$set(organization, 'workflows', workflows)
    }
  }
}
</script>
<style>
.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: auto;
}
.flex-item {
  margin: 10px;
  text-align: center;
}</style>
