import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getFirestore, connectFirestoreEmulator, doc, getDoc } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, signOut, connectAuthEmulator } from 'firebase/auth'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMaterialTabs from 'vue-material-tabs'

library.add(faAngleRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueMaterialTabs)

const app = initializeApp({
  projectId: 'techzip-demo',
  apiKey: 'AIzaSyCpXrWorBCrq6wFBOAsfeZzyTsS8isWa1A',
  authDomain: 'techzip-demo.firebaseapp.com',
  appId: '1:167866847617:web:67c1215e39aad67e8c9880'
})
const auth = getAuth(app)
const functions = getFunctions(app)
const firestore = getFirestore(app)

if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFirestoreEmulator(firestore, 'localhost', 8090)
}

Vue.prototype.$functions = functions
Vue.prototype.$firestore = firestore
Vue.prototype.$auth = auth

Vue.config.productionTip = false

// appInit forces Vue to launch only when onAuthStateChanged as completed once
let appInit

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    user.getIdTokenResult(true).then((token) => {
      const userRef = doc(firestore, 'users', user.uid)

      getDoc(userRef).then(doc => {
        console.log('user doc exists', doc.exists())
        if (doc.exists()) {
          const userData = doc.data()
          userData.id = user.uid
          userData.role = token.claims.role
          store.dispatch('signIn', userData)

          if (router.currentRoute.path === '/sign-in') {
            // router.push(['admin', 'tech'].includes(userData.role) ? '/admin' : '/') todo: change this to go to a route based on role
            router.push('/list-processes')
          }
        } else {
          signOut(auth)
        }
      }).catch(error => {
        console.error(error)
        // signOut(auth)
      })
    })
  } else {
    store.dispatch('signOut')
    if (router.currentRoute.path !== '/sign-in') router.push('/sign-in')
  }

  if (!appInit) {
    appInit = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
