<template>
  <div>
    <ViewHeader title="Process" />
    <div style="background-color: #fff; overflow: hidden; background-color: #fff; position: fixed; left: 0; top: 51px; right: 0; height: 180px;">
      <div style="display: flex; justify-content: space-between;">
        <div style="font-size: 150%; padding-left: 20px; line-height: 80px; padding-top: 10px;">
          {{ workflowName }}
        </div>
        <div style="margin-right: 20px; line-height: 80px;">
          <button class="button" @click="goBack()">
            Back
          </button>
          <button class="button" @click="goForward()">
            Forward
          </button>
          <button class="button" @click="save()">
            Save
          </button>
        </div>
      </div>
    </div>
    <div style="overflow: auto; background-color: #f6f6f6; position: fixed; top: 205px; right: 0; bottom: 0; left: 0; padding-left: 20px; padding-top: 20px;" v-ref="stepsGrid">
      <div style="display: grid; grid-auto-columns: min-content;">
        <div v-for="cell in cells" :key="cell.index" :style="{'grid-column': cell.x, 'grid-row': cell.y}">
          <div v-if="cell.elementType == 'connector'" style="width: 80px; height: 100%;" @drop="onDrop($event, cell)" @dragover.prevent @dragenter.prevent class="connector" @click="insertStep(cell.priorStepId)">
            <svg v-if="cell.connectorType == 'T'" xmlns="http://www.w3.org/2000/svg" height="100%">
              <defs>
                <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3" orient="auto">
                  <polygon points="0 0, 6 3, 0 6" />
                </marker>
              </defs>
              <line x1="2" y1="75" x2="68" y2="75" stroke="#000" stroke-width="2" marker-end="url(#arrowhead)" />
              <line x1="40" y1="75" x2="40" y2="100%" stroke="#000" stroke-width="2" />
            </svg>
            <svg v-else-if="cell.connectorType == '-'" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3" orient="auto">
                  <polygon points="0 0, 6 3, 0 6" />
                </marker>
              </defs>
              <line x1="0" y1="75" x2="70" y2="75" stroke="#000" stroke-width="2" marker-end="url(#arrowhead)" />
            </svg>
            <svg v-else-if="cell.connectorType == '+'" xmlns="http://www.w3.org/2000/svg" height="100%">
              <defs>
                <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3" orient="auto">
                  <polygon points="0 0, 6 3, 0 6" />
                </marker>
              </defs>
              <line x1="40" y1="75" x2="70" y2="75" stroke="#000" stroke-width="2" marker-end="url(#arrowhead)" />
              <line x1="40" y1="0" x2="40" y2="100%" stroke="#000" stroke-width="2" />
            </svg>
            <svg v-else-if="cell.connectorType == 'L'" xmlns="http://www.w3.org/2000/svg" height="100%">
              <defs>
                <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3" orient="auto">
                  <polygon points="0 0, 6 3, 0 6" />
                </marker>
              </defs>
              <line x1="40" y1="75" x2="70" y2="75" stroke="#000" stroke-width="2" marker-end="url(#arrowhead)" />
              <line x1="40" y1="0" x2="40" y2="75" stroke="#000" stroke-width="2" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" height="100%">
              <line x1="40" y1="0" x2="40" y2="100%" stroke="#000" stroke-width="2" />
            </svg>
          </div>
          <div v-else style="width: 250px; padding: 10px 0;" draggable @dragstart="startDrag($event, cell)" @click="viewStep(cell.stepId)" :class="{ 'step-not-open' : !cell.isEditMode }" :ref="`box-${cell.stepId}`">
            <div style="display: flex; justify-content: flex-start; flex-direction: column;">
              <div style="background-color: white; box-shadow: 1px 2px 3px 1px #ddd; padding: 0 10px; line-height: 40px; font-size: 90%; background-color: #e6f2ff; border: 1px solid #aaa; border-top-left-radius: 6px; border-top-right-radius: 6px; border-bottom-width: 0;">
                Step {{ cell.label }}
              </div>
              <div style="background-color: white; box-shadow: 1px 2px 3px 1px #ddd; border-style: solid; border-width: 1px; border-top-width: 0; border-color: #aaa; border-bottom-right-radius: 6px; border-bottom-left-radius: 6px; border-top-width: 0;">
                <div v-if="cell.isEditMode" style=" border: 0px solid red; padding: 0 5px; border: 1px solid #ccc; margin: 10px; border-radius: 4px;">
                  <textarea style="width: 95%; margin: 5px; min-height: 50px; border-width: 0; outline-width: 0; resize: none;" :value="cell.description" :placeholder="cell.placeholder" v-on:keyup.enter="saveStep(cell.stepId)" :ref="cell.refId"></textarea>
                </div>
                <div v-else style="min-height: 70px; padding: 10px; line-height: 125%; text-align: center;">
                  <div v-if="cell.elementType == 'sequence'">
                    {{ cell.description }}
                  </div>
                  <div v-else-if="cell.elementType == 'jump'">
                    {{ cell.description }}
                    <hr />
                    {{ cell.extraDescription }}
                  </div>
                  <div v-else-if="cell.elementType == 'trigger'">
                    Trigger {{ cell.description }}
                  </div>
                  <div v-else-if="cell.elementType == 'goal'">
                    Process complete!
                  </div>
                </div>
                <div style="border-top: 1px solid #ccc; padding: 10px;">
                  more stuff
                </div>
                <div v-show="selectedStepId == cell.stepId" style="padding: 10px; display: flex; justify-content: space-around;">
                  <button @click="editStep(cell.stepId)">Edit</button>
                  <button @click="deleteStep(cell.stepId)">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-show="isModalVisible"
      @close="closeModal">
      <template v-slot:body>
        <!-- <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fm8xgIFw7RsOsID1rm1r60b%2FZygg%3Fnode-id%3D17%253A120%26t%3Ddp6uKDlqsutJgbyZ-1" allowfullscreen></iframe> -->
      </template>
    </Modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { httpsCallable } from 'firebase/functions'
import Modal from '../components/Modal.vue'
import ViewHeader from '../components/ViewHeader.vue'
// import ViewStep from '@/components/ViewStep.vue'
import { doc, getDoc, setDoc } from 'firebase/firestore'

import _ from 'lodash'

let history = []

export default {
  name: 'EditWorkflow',
  components: {
    Modal,
    ViewHeader
  },
  data: function () {
    return {
      isModalVisible: false,
      xsteps: JSON.parse(`[{"id":0,"parentId":false,"type":"action","label":"1","description":"sadfsf"},{"id":1,"parentId":0,"type":"action","isIfStep":true,"isOtherwiseStep":false,"label":"2.1","description":"if sadf"},{"id":2,"parentId":1,"type":"action","isIfStep":false,"isOtherwiseStep":false,"label":"3.1","description":"sdfsa"},{"id":3,"parentId":0,"type":"action","isIfStep":false,"isOtherwiseStep":true,"label":"2.2","description":"otherwise, asdfsd"},{"id":4,"parentId":3,"type":"action","isIfStep":false,"isOtherwiseStep":false,"label":"3.2","description":"asfsdfdsa"},{"id":5,"parentId":4,"type":"action","isIfStep":false,"isOtherwiseStep":false,"label":"4.2","description":"asdfsdf"},{"id":6,"parentId":5,"type":"open","isIfStep":false,"isOtherwiseStep":false,"label":"5.2"},{"id":7,"parentId":2,"type":"action","isIfStep":true,"isOtherwiseStep":false,"label":"4.1.1","description":"if asdf"},{"id":8,"parentId":7,"type":"open","isIfStep":false,"isOtherwiseStep":false,"label":5},{"id":9,"parentId":2,"type":"open","isIfStep":false,"isOtherwiseStep":true,"label":"4.1.2"}]`), // eslint-disable-line
      ysteps: [
        {
          id: 0,
          parentId: false,
          type: 'open',
          label: '1',
          jumpToTarget: null
        }
      ],
      currentHistoryIndex: 0,
      selectedStepId: null,
      grid: [],
      cells: [],
      workflowName: '',
      addStepDescription: '',
      currentNodeId: 0,
      antecedentFunctions: [],
      stepDescriptions: [''],
      screenX: null,
      screenY: null
    }
  },
  // async beforeRouteUpdate (to, from) {
  //   // react to route changes...
  //   // this.userData = await fetchUser(to.params.id)
  // },v-on:keydown.enter="saveStep(cell.stepId)"
  computed: {
    currentSteps () {
      return history[this.currentHistoryIndex].steps
    }
  },
  async mounted () {
    // organizations/V6XSkYB2WPZuxmGEgXml/releases/gEdELBjItXMgpaQt4tkc/workflows/MhgsRp5YqOIxWaVVUJgK
    const docRef = doc(this.$firestore, `organizations/${this.$route.params.organizationId}/workflows`, this.$route.params.workflowId)
    console.log('mounted', docRef)
    // const docRef = doc(this.$firestore, `workflows/${this.$route.params.workflowId}`)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      this.workflowName = docSnap.data().name
      history.push({
        steps: docSnap.data().steps
      })
      this.currentHistoryIndex = 0
    } else {
      // doc.data() will be undefined in this case
      alert('no dice!')
    }
    this.processSteps()
    this.makeTable()
    // if (this.steps.length === 1) {
    //   // empty process
    //   this.selectedStep = this.steps.slice(0, 1).id
    // }
    // this.setInputFocus() // this.steps[0].id
  },
  methods: {
    goBack () {
      console.log('current index', this.currentHistoryIndex)
      this.currentHistoryIndex = this.currentHistoryIndex > 0 ? this.currentHistoryIndex - 1 : 0
      this.processSteps()
      this.makeTable()
      console.log('after index', this.currentHistoryIndex)
    },
    goForward () {
      console.log('current index', this.currentHistoryIndex)
      this.currentHistoryIndex = history.length - this.currentHistoryIndex > 1 ? this.currentHistoryIndex + 1 : this.currentHistoryIndex
      this.processSteps()
      this.makeTable()
      console.log('after index', this.currentHistoryIndex)
    },
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    },
    setInputFocus () { // stepId
      this.$nextTick(
        function () {
          if (`step-${this.selectedStepId}` in this.$refs && this.$refs[`step-${this.selectedStepId}`].length) {
            this.$refs[`step-${this.selectedStepId}`][0].focus()
          }
        }.bind(this)
      )
    },
    startDrag (event, cell) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('draggedStepId', cell.stepId)
    },
    onDrop (event, cell) {
      // this moves a cell
      console.log('drop!')
      const newSteps = _.cloneDeep(this.currentSteps)

      const draggedStepId = parseInt(event.dataTransfer.getData('draggedStepId'))
      const draggedStep = newSteps.find(step => step.id === draggedStepId)

      console.log('found dragged step', draggedStep)

      const destinationStep = newSteps.find(step => step.id === cell.priorStepId)

      console.log('found destination step', destinationStep)

      const destinationParentStepId = destinationStep.parentId

      const draggedChildSteps = newSteps.filter(step => step.parentId === draggedStep.id)

      for (const childStep of draggedChildSteps) {
        childStep.parentId = draggedStep.parentId
      }

      const destinationChildSteps = newSteps.filter(step => step.parentId === destinationParentStepId)

      for (const childStep of destinationChildSteps) {
        childStep.parentId = draggedStep.id
      }

      draggedStep.parentId = destinationParentStepId

      // prune any revisions past this one
      if (history.length - this.currentHistoryIndex > 1) {
        history.splice(this.currentHistoryIndex + 1)
      }

      history = history.concat({
        steps: newSteps
      })

      this.currentHistoryIndex = history.length - 1
      this.selectedStepId = draggedStep.id

      this.processSteps()
      this.makeTable()
    },
    getNextStepId (steps) {
      const stepIds = steps.map(step => step.id)
      console.log('next step ID', Math.max(...stepIds) + 1)
      return Math.max(...stepIds) + 1
    },
    viewStep (stepId) {
      const currentStep = this.currentSteps.find(step => step.id === this.selectedStepId)
      if (currentStep) {
        // there might not be a currently selected step, for example, after deleting a step
        currentStep.type = 'sequence'
        currentStep.isEditMode = !currentStep.description.length
      }
      this.selectedStepId = stepId
    },
    save () {
      // remove unnecessary information from steps data
      const cleanSteps = []

      for (const step of this.currentSteps) {
        const base = {
          id: step.id,
          parentId: step.parentId,
          type: step.type,
          description: step.description,
          label: step.label
        }

        if (step.type === 'jump') {
          base.jumpStepTargetId = step.jumpStepTargetId
        }
        cleanSteps.push(base)
      }

      setDoc(doc(this.$firestore, `organizations/${this.$route.params.organizationId}/workflows`, this.$route.params.workflowId), {
        name: this.workflowName,
        steps: cleanSteps
      }, {
        merge: true
      }).then(() => {
        console.log('saved!')
      }).catch(error => console.log(error))
    },
    deleteStep (stepId) {
      const newSteps = _.cloneDeep(this.currentSteps)

      const selectedStepIndex = newSteps.findIndex(step => step.id === stepId)
      const newParentId = newSteps[selectedStepIndex].parentId

      const childSteps = newSteps.filter(step => step.parentId === stepId)

      newSteps.splice(selectedStepIndex, 1)

      childSteps.forEach(step => {
        step.parentId = newParentId
      })

      // prune any revisions past this one
      if (history.length - this.currentHistoryIndex > 1) {
        history.splice(this.currentHistoryIndex + 1)
      }

      history = history.concat({
        steps: newSteps
      })

      this.currentHistoryIndex = history.length - 1
      this.selectedStepId = null
      this.processSteps()
      this.makeTable()
    },
    editStep (stepId) {
      console.log('edit step', stepId)
      const step = this.currentSteps.find(step => step.id === stepId)
      step.isEditMode = true
      this.selectedStepId = stepId
      this.makeTable()
    },
    insertStep (nextStepId) {
      // the connector is added after the step is placed in the table, so it contains the stepId after the connector
      const newSteps = _.cloneDeep(this.currentSteps)

      const nextStep = newSteps.find(step => step.id === nextStepId)
      const allNextSteps = newSteps.filter(step => step.parentId === nextStep.parentId) // in case it's an if branch and we need to update multiple steps

      const insertedStepId = this.getNextStepId(newSteps)

      newSteps.push({
        id: insertedStepId,
        type: 'sequence',
        isEditMode: true,
        parentId: nextStep.parentId
      })

      for (const nextStep of allNextSteps) {
        nextStep.parentId = insertedStepId
      }

      // prune any revisions past this one
      if (history.length - this.currentHistoryIndex > 1) {
        history.splice(this.currentHistoryIndex + 1)
      }

      history = history.concat({
        steps: newSteps
      })

      this.currentHistoryIndex = history.length - 1
      this.selectedStepId = null
      this.processSteps()
      this.makeTable()
    },
    processSteps () {
      const labelChildSteps = function (parentStep, parentStepNumber, branches, steps) {
        // 1 - 2 - 3.1 etc.
        //         3.2
        const childSteps = steps.filter(step => step.parentId === parentStep.id)
        const nextStepNumber = parentStepNumber + 1

        if (childSteps.length > 1) {
          // this must be a set of if/otherwise steps
          let newBranchNumber = 1

          childSteps.forEach(childStep => {
            let childBranches = Array.from(branches)

            if (childBranches.length) {
              childBranches.push(newBranchNumber)
            } else {
              childBranches = [newBranchNumber]
            }
            // console.log(`Parent step ID: ${parentStep.id}, Child ID: ${childStep.id}, new step # ${nextStepNumber}, new branch # ${newBranchNumber}, branches`, branches, childBranches)

            childStep.label = branches.length ? `${nextStepNumber}.${branches.join('.')}.${newBranchNumber}` : `${nextStepNumber}.${newBranchNumber}`

            labelChildSteps(childStep, nextStepNumber, childBranches, steps)
            newBranchNumber++
          })
        } else if (childSteps.length === 1) {
          childSteps[0].label = branches.length ? `${nextStepNumber}.${branches.join('.')}` : nextStepNumber.toString()
          labelChildSteps(childSteps[0], nextStepNumber, branches, steps)
        }
      }

      // make sure there's an 'otherwise' placeholder step if there's an open if step
      this.currentSteps.forEach((step, index, steps) => {
        if (step.isIfStep) {
          // make sure otherwise step is along with it
          const siblings = steps.filter(siblingStep => siblingStep.parentId === step.parentId)

          // alert(step.id + ' ' + siblings.length)

          const lastSibling = siblings.pop()

          if (!lastSibling.isOtherwiseStep) { // lastSibling.id === step.id &&
            const cellData = {
              id: this.getNextStepId(this.currentSteps),
              parentId: step.parentId,
              type: 'sequence',
              isEditMode: true,
              isIfStep: false,
              isOtherwiseStep: true
            }

            steps.push(cellData)
          }
        }
      })

      const firstStep = history[this.currentHistoryIndex].steps.find(step => step.parentId === false)
      labelChildSteps(firstStep, 1, [], history[this.currentHistoryIndex].steps)
    },
    deleteStepsAfter (stepId, steps) {
      for (const step of steps) {
        const subStepId = step.id
        if (step.parentId === stepId) {
          const stepIndex = steps.findIndex(step => step.id === subStepId)
          steps.splice(stepIndex, 1)
          this.deleteStepsAfter(subStepId, steps)
        }
      }
    },
    saveStep (stepId) {
      const description = this.$refs[`step-${stepId}`][0].value.replace(/(\r\n|\n|\r)/gm, '').trim() // remove line breaks
      if (!description.length) { // only continue when enter is pressed with content
        return
      }

      const newSteps = _.cloneDeep(this.currentSteps)

      const step = newSteps.find(step => step.id === stepId)
      step.description = description
      step.type = 'sequence'
      step.isEditMode = false

      const nextStepId = this.getNextStepId(newSteps)

      const parts = description.split(' ').filter(part => part.length).map(part => part.toLowerCase())

      if (step.isOtherwiseStep && !['if', 'otherwise,'].includes(parts[0])) { // do not allow a conditional branch to not be either if or otherwise
        alert('nope')
      } else {
        if (parts[0] === 'if') {
          // const parentStep = this.steps.find(parentStep => parentStep.id === step.parentId)
          step.isIfStep = true
          step.isOtherwiseStep = false

          if (!newSteps.find(step => step.parentId === stepId)) {
            newSteps.push({
              id: nextStepId,
              parentId: step.id,
              type: 'sequence',
              isEditMode: true,
              isIfStep: false,
              isOtherwiseStep: false
            })
          }
        } else if (parts[0] === 'jump') {
          step.type = 'jump'
          step.jumpStepTargetId = null
          const stepLabel = parts.pop()
          const targetStep = newSteps.find(step => step.label.toLowerCase() === stepLabel.toLowerCase())

          if (targetStep) {
            step.jumpStepTargetId = targetStep.id
          }

          // prune steps after the jump step, if any
          this.deleteStepsAfter(step.id, newSteps)
        } else if (parts[0] === 'trigger') {
          step.type = 'trigger'
        } else if (parts[0] === 'done') {
          step.type = 'goal'
        } else {
          console.log(`Addinng step id ${nextStepId} with parent Id ${step.id}`)

          // only insert a step if it's at the end of a branch
          if (!newSteps.find(step => step.parentId === stepId)) {
            newSteps.push({
              id: nextStepId,
              parentId: step.id,
              type: 'sequence',
              isEditMode: true,
              isIfStep: false,
              isOtherwiseStep: false
            })
          }
        }
        this.addStepDescription = ''

        // prune any revisions past this one
        // if (this.history.length - this.currentHistoryIndex > 1) {
        //   this.history.splice(this.currentHistoryIndex + 1)
        // }

        // this.history.push(newSteps)
        history = history.concat({
          steps: newSteps
        })
        this.currentHistoryIndex = history.length - 1
        this.selectedStepId = nextStepId
        this.processSteps()
        this.makeTable()
        // this.setInputFocus(nextStepId)
      }
    },
    validateStepDescription () {
      const validateStepDescription = httpsCallable(this.$functions, 'validateStepDescription')
      validateStepDescription({ description: this.addStepDescription })
        .then((result) => {
          alert(result.data.result)
        })
      // alert(this.addStepDescription)
    },
    makeTable () {
      const grid = history[this.currentHistoryIndex].steps.slice() // make a copy; JSON.parse(JSON.stringify(this.steps))
      const cells = []

      const countSiblingsOfChildren = function (gridElement) {
        let totalSiblings = 0

        if (gridElement.childSteps.length) {
          gridElement.childSteps.forEach(childStep => {
            if (childStep.priorChildStep !== false) {
              totalSiblings++
            }
            totalSiblings += countSiblingsOfChildren(childStep)
          })
        }
        return totalSiblings
      }

      const addGridCoordinates = function (gridElement, x, y) {
        gridElement.x = x
        gridElement.y = y

        if (gridElement.priorChildStep !== false && 'priorChildStep' in gridElement) { // means it's not on the top row of the grid and not the origin element
          let currentPriorChildStep = gridElement.priorChildStep
          while (currentPriorChildStep !== false) {
            gridElement.y += countSiblingsOfChildren(currentPriorChildStep)
            // console.log(gridElement.summary, currentPriorChildNode.summary, x, y, gridElement.y, gridElement.hasNextChildNode)
            currentPriorChildStep = currentPriorChildStep.priorChildStep
          }
        }

        // Now add the connector element
        if (gridElement.parentId !== false) {
          let connector = '-'
          if (gridElement.priorChildStep !== false && gridElement.hasNextChildStep !== false) {
            connector = '+'
          } else if (gridElement.priorChildStep !== false && gridElement.hasNextChildStep === false) {
            connector = 'L'
          } else if (gridElement.priorChildStep === false && gridElement.hasNextChildStep === true) {
            connector = 'T'
          }

          grid.push({
            x: x - 1,
            y: gridElement.y,
            type: 'connector',
            connectorType: connector,
            priorStepId: gridElement.id
          })
        }
        gridElement.childSteps.forEach((childStep, index) => addGridCoordinates(childStep, x + 2, gridElement.y + index))
      }

      // add data to steps for the purpose of rendering
      grid.forEach(element => {
        element.childSteps = []
        element.x = 0
        element.y = 0
        element.description = 'description' in element ? element.description : ''
      })

      // add sibling relationships - this is used to determine the y grid value
      grid.forEach(element => {
        const childElements = grid.filter(childElement => childElement.parentId === element.id)
        childElements.forEach((childElement, childIndex) => {
          childElement.priorChildStep = childIndex > 0 ? childElements[childIndex - 1] : false
          childElement.hasNextChildStep = childIndex < childElements.length - 1
        })
        element.childSteps = childElements
      })

      // find origin grid element
      const originGridElement = grid.find(element => element.parentId === false)

      // console.log(grid)
      // now go through and add x,y coordinates, starting with 1, 1 at the origin
      addGridCoordinates(originGridElement, 1, 1)

      this.grid = grid

      // now fill in the rest of the connector pieces
      let x
      let y = 1

      // loop until we run out of rows and columns
      while (grid.findIndex(element => element.y === y) !== -1) {
        x = 1
        while (grid.findIndex(element => element.x === x) !== -1) {
          // get every element on this x axis
          const elementIndex = grid.findIndex(element => element.x === x && element.y === y)
          if (elementIndex !== -1) {
            const element = grid[elementIndex]
            if (element.type !== 'connector') {
              let placeholder = parseInt(element.label.split('.')[0]) % 2 ? 'User clicks...' : 'App displays...'
              // let placeholder = `Label ${element.label}`
              if (element.isOtherwiseStep) {
                placeholder = 'Otherwise...'
              }

              let extraDescription = null
              if (element.type === 'jump') {
                const targetStep = history[this.currentHistoryIndex].steps.find(targetStep => targetStep.id === element.jumpStepTargetId)
                console.log('found target step in process')
                if (targetStep) {
                  extraDescription = targetStep.description
                } else {
                  extraDescription = 'Step missing'
                }
              }

              cells.push({
                refId: `step-${element.id}`,
                stepId: element.id,
                parentId: element.parentId,
                x,
                y,
                elementType: element.type,
                isEditMode: element.isEditMode,
                placeholder,
                // description: element.type !== 'open' ? element.description : '',
                description: element.description !== '' ? element.description : '',
                extraDescription,
                label: element.label,
                isIfStep: element.isIfStep,
                isOtherwiseStep: element.isOtherwiseStep
              })
            } else {
              cells.push({
                x,
                y,
                elementType: 'connector',
                connectorType: element.connectorType,
                priorStepId: element.priorStepId
              })
            }
          } else {
            // see if the cell above it is a connector, and if so, draw a vertical line
            if (x % 2 === 0) {
              const cellAboveIndex = cells.findIndex(cell => cell.x === x && cell.y === y - 1)
              if (cellAboveIndex !== -1 && cells[cellAboveIndex].elementType === 'connector' && ['T', '+', '|'].includes(cells[cellAboveIndex].connectorType)) {
                cells.push({
                  x,
                  y,
                  elementType: 'connector',
                  connectorType: '|',
                  priorStepId: cells[cellAboveIndex].priorStepId
                })
              }
            }
          }
          x++
        }
        y++
      }
      this.cells = cells
      if (this.selectedStepId === null) {
        // empty process
        this.selectedStepId = cells[0].stepId // this isn't guaranteed to be zero
      }
      this.setInputFocus()
    }
  }
}
</script>
<style scoped>
  /* Style the list */
  ul.breadcrumb {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  /* Display list items side by side */
  ul.breadcrumb li {
    display: inline;
    font-size: 18px;
  }

  /* Add a slash symbol (/) before/behind each list item */
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: #aaa;
    content: "/";
    font-weight: bold;
  }

  /* Add a color to all links inside the list */
  ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
  }

  /* Add a color on mouse-over */
  ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
  }
  .tab-content {
      padding:25px;
  }
  /* https://codepen.io/digitalinfamy/pen/oEOaON */
  .material-tabs {
      position: relative;
      display: block;
      padding: 0 20px;
      border-bottom: 1px solid #e0e0e0;
  }

  .material-tabs>div {
      position: relative;
      display:inline-block;
      text-decoration: none;
      padding: 22px;
      min-width: 40px;
      font-size: 14px;
      font-weight: 600;
      color: #424f5a;
      text-align: center;
      border-bottom: 3px transparent;
  }

  .material-tabs>div.active {
      font-weight: 700;
      outline: none;
      border-bottom: 3px solid #458CFF;
  }

  .material-tabs>div:not(.active):hover {
      background-color: inherit;
      color: #7c848a;
      cursor: pointer;
  }
  .step-not-open:hover {
    cursor: pointer;
  }

  .connector:hover {
    cursor: pointer;
  }
  #nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  margin-right: 15px;
}

#nav a.router-link-exact-active {
  color: #2c3e50;
}

.button {
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
</style>
